import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { downloadsstatic, programsstatic, genresstatic, mtkeysstatic, qrsheetsstatic, herostatic  } from '../assets/static/data'

const ProgramContext = createContext()

export const ProgramContextProvider = ({ children }) => {
  const [programs, setPrograms] = useState([])
  const [hero, setHero] = useState([])
  const [genres, setGenres] = useState([])
  const [mtKeys, setMtKeys] = useState([])
  const [qrSheets, setQrSheets] = useState([])
  const [downloads, setDownloads] = useState([])

  useEffect(() => {
    setPrograms(programsstatic)
    setGenres(genresstatic)
    setHero(herostatic)
    setMtKeys(mtkeysstatic)
    setQrSheets(qrsheetsstatic)
    setDownloads(downloads)
  }, [])
  
  const getPromo = async(id) => {
    const lambdaEndpoint = `https://y1o57glwya.execute-api.us-east-1.amazonaws.com/prod/data/promo-email`
    const requestBody = {
      id: id
    }
    
    try {
      const response = await axios.post(lambdaEndpoint, requestBody, {
        headers: {
          'Content-Type': 'application/json' 
        }
      })
      return response.data // Return the data for further processing
    } catch (error) {
      console.error('Error making POST request:', error)
    }  
  }
  

  return (
    <ProgramContext.Provider value={{
      programs,
      genres,
      mtKeys,
      hero,
      qrSheets,
      downloadsstatic,
      getPromo
    }}>
      {children}
    </ProgramContext.Provider>
  )
}

export default ProgramContext
