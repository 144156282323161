import React from 'react'
import Container from 'react-bootstrap/Container'
import ProgramTombstone from './ProgramTombstone'
import ProgramDescription from './ProgramDescription'

const ProgramMetadata = ({program}) => {
  return (
    <Container className='program-metadata-container pt-5'>
      <ProgramTombstone program={program}  />
      <ProgramDescription program={program} />      
    </Container>
  )
}

export default ProgramMetadata
