import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Footer from '../components/layout/Footer'
import Navigation from '../components/layout/Navigation'

const RootLayout = () => {
  const params = useParams()
  
  // Function to check if params object is empty or contains specific keys
  const shouldUseNavigationWrapper = () => {
    // Check if params is empty
    const isParamsEmpty = Object.keys(params).length === 0
    // Check for specific keys in params
    const hasIdKey = 'id' in params

    return isParamsEmpty || hasIdKey
  }

  // Determine the class based on the params
  const wrapperClass = shouldUseNavigationWrapper() ? 'gradient-wrapper' : ''

  return (
    <div className='root-layout'>
      <a href='#main-content' className='skip-link'>Skip to main content</a>
      <div className={wrapperClass}>
        <Navigation />
      </div>
      <main id='main-content' tabIndex='-1'>
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export default RootLayout
