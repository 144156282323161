import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import ProgramContext from '../../context/ProgramContext'
import MySpinner from '../utilities/MySpinner'

const PromoPage = () => {
  const { getPromo } = useContext(ProgramContext)
  const [ promo, setPromo ] = useState()
  const [ links, setLinks ] = useState()
  const params = useParams()
  const id = params.id

  useEffect(() => {
    const fetchPromo = async () => {
      const res = await getPromo(id)
      setPromo(res)
    }
    fetchPromo()
  }, [id, getPromo])

  useEffect(() => {
    if(promo) {
      const { links } = promo
      setLinks(links)

    }
  }, [promo])

  const handleDownloadButtonClick = (url) => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'screener download', { 
        'event_category': 'Screener Download',
        'event_label': url.split('/').pop(),
        'user_email': promo.buyerEmail, // This assumes user_email is setup as a custom dimension in GA4.
        'user_company': promo.buyerCompany
      });
    }
  }

  if(!promo || !links) {
    return <MySpinner />
  }

  return (
    <Container className='promo-container'>
      <h1 className='text-center'>Welcome {promo.buyerName}</h1>
      <div className='col-10 offset-1 mt-3'>
        { links.map(link => {
          return (
            <div className='promo-video-grid mb-2' key={link.id}>
              <div className='text-center mt-3'>
                <Link to={`/programs/${link.id}/${link.programTitle.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`} ><h5 className='mb-3'>{link.displayTitle}</h5></Link>
                <img src={link.img} alt='' />
                <p className='mt-3'>Episodes & Length: {link.epLength}</p>             
              </div>
              <div className='promo-download-desc'>
                <p>{link.desc}</p> 
                <div  className='text-center'>
                  <a href={link.url}
                    onContextMenu={(e)=> handleDownloadButtonClick(link.url)} 
                    onClick={(e)=> handleDownloadButtonClick(link.url)}
                  >Right click to download</a><br />
                  <small>select "Save Link as..."</small>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default PromoPage
