const rearrangeString = (str) => {
  // Split the string into words
  const words = str.split(' ')

  // Check if the last word is 'A' or 'The'
  if (words.length > 1 && (words[words.length - 1] === 'A' || words[words.length - 1] === 'The')) {
      // Remove the last word
      const lastWord = words.pop()

      // Remove the comma from the new last word if it exists
      words[words.length - 1] = words[words.length - 1].replace(/,$/, '')

      // Reassemble the string with the last word at the front
      return `${lastWord} ${words.join(' ')}`
  }

  // Return the original string if no changes are needed
  return str
}

const reconstructTitle = (urlSlug) => {
  const title = urlSlug.replace(/\b(\w)/g, (match) => match.toUpperCase()).replace(/-/g, ' ')
  return title
}

const setBackground = () => {
  document.addEventListener("DOMContentLoaded", function() {
    const mainElement = document.querySelector('main')
    const longReadContainerExists = document.querySelector('.long-read-container') !== null
    const socialMediaRowExists = document.querySelector('.social-media-row') !== null
  
    if (longReadContainerExists || socialMediaRowExists) {
      mainElement.classList.add('subheader-background')
    }
  })  
}

const getGenreUrl = (name) => {
  const genreMapping = {
    'Art & Culture': '1/art-&-culture',
    'Biography': '2/biography',
    'Business': '3/business',
    'Current Affairs': '4/current-affairs',
    'General Documentaries': '5/general-documentaries',
    'History': '6/history',
    'IFE': '7/IFE',
    'Lifestyle': '8/lifestyle',
    'Military': '9/military',
    'Performance': '10/performance',
    'Science Health & Nature': '11/science,-health-&-nature',
    'War Up Close': '12/war-up-close',
  }
  return genreMapping[name];
}

const handleImageError = (e) => {
  e.target.src = 'https://images.aptww.org/IntlCatalog.nsf/vGenres/Art & Culture/$FILE/art_culture_1920x1080.jpg' // Default image path
}

const truncateStringAtFirstSpaceAfterLimit = (str, maxLength) => {
  if (str.length <= maxLength) return str // Return early if within limit
  // Find the index of the first space after maxLength
  const indexOfNextSpace = str.indexOf(' ', maxLength)
  // If there's a space after maxLength, truncate and add ellipsis
  if (indexOfNextSpace !== -1) {
    return `${str.substring(0, indexOfNextSpace)}...`
  }
  // If no space found, return the whole string up to maxLength and ellipsis
  return `${str.substring(0, maxLength)}...`
}

// Usage
export { 
  getGenreUrl,
  rearrangeString, 
  setBackground,
  reconstructTitle,
  handleImageError,
  truncateStringAtFirstSpaceAfterLimit
}