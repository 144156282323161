import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'

const ProgramCard = ({ program }) => {
  const [urlSlug, setUrlSlug] = useState('')

  useEffect(() => {
    const rawTitle = program.title
    const newTitle = rawTitle.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()
    setUrlSlug(newTitle)
  }, [program])

  return (
    <Card className='program-card'>
      <Link to={`/programs/${program.idNumber}/${urlSlug}`} state={{ program }} onClick={() => window.scroll(0, 0)}>
        <div className='card-img-wrapper'>
          <Card.Img variant='top' src={program.imgSrc} alt={`Feature image for ${program.featuredTitle}`} />
          <div className='program-card-overlay'>
            <Row>
              <Col>{program.shortDesc.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?b>/gi, '').replace(/<\/?i>/gi, '')}</Col>
            </Row>
          </div>
        </div>
        <Card.Body>
          <Card.Title className='fw-bold text-center'>{program.featuredTitle} - {program.epCount.replace(/\//g, 'x')}</Card.Title>
        </Card.Body>
      </Link>
    </Card>
  )
}

export default ProgramCard
