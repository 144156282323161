import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'

const GenreCard = ({ genre }) => {
  const [ urlSlug, setUrlSlug ] = useState()

  useEffect(() => {
    const rawTitle = genre.name
    const newTitle = rawTitle.replace(/\s+/g, '-').toLowerCase()
    setUrlSlug(newTitle)
  }, [genre])

  return (
    <Card className='genre-card mt-5'>
      <Link to={`/genres/${genre.id}/${urlSlug}`} onClick={() => window.scroll(0, 0)}>
      <Card.Img variant='top' src={genre.imageURL} alt={`Feature image for ${genre.name}`} />
        <Card.Body>
          <Card.Title className='fw-bold'>{genre.name}</Card.Title>
        </Card.Body>
      </Link>
    </Card>
  )
}
export default GenreCard