import React, { useContext, useEffect, useState } from 'react'
import MySpinner from '../utilities/MySpinner'
import { FileEarmarkPdfFill } from 'react-bootstrap-icons'
import ProgramContext from '../../context/ProgramContext'

const ProgramDescription = ({program}) => {
  const { programs } = useContext(ProgramContext)
  const [description, setDescription] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [relatedLinks, setRelatedLinks] = useState([]) 
  const { related } = program

  useEffect(() => {
    const encodedText = program.longDesc
    const decodedText = decodeURIComponent(encodedText.replace(/\+/g, ' '))
    const cleanText = decodedText.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?b>/gi, '').replace(/<\/?i>/gi, '')
    const textArray = cleanText.split('\n')
    setDescription(textArray)
  },[program])

  useEffect(() => {
    // Process each related program's ID to find its details and construct new strings
    const newRelatedLinks = related.map(relatedProgramId => {
      const foundProgram = programs.find(p => p.id === relatedProgramId)
      if (foundProgram) {
        const modifiedTitle = foundProgram.featuredTitle.replace(/\s+/g, '-') // Replace spaces with dashes
        return `https://aptworldwide.com/programs/${foundProgram.id}/${encodeURIComponent(modifiedTitle)}` // Construct the new string
      }
      return null // In case no matching program is found
    }).filter(link => link !== null) // Remove any null values

    setRelatedLinks(newRelatedLinks)
  }, [program, programs])
  
  if(!program) return <MySpinner />
  return (
    <div className='program-description' itemscope itemtype='https://schema.org/TVSeries' itemid={`#${program.idNumber}`}>
      <div className='print-container' itemprop='abstract'>        
      {description.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
      </div>
      <div className='program-press mb-3' itemprop='review'>
        { program.pA.length > 0 && <h4>Press Links</h4>}
        {program.pA?.map(press => (
          <div className='row mb-1'>
            {/* NEED TO REMOVE ABSOLUTE VARIABLE AND USE DYNAMIC ENV VARIABLES FOR URL */}
            <a href={`https://images.aptww.org/${press.url}`} target='_blank' rel='noreferrer'><FileEarmarkPdfFill color='white' className='me-2' />{ press.pressTitle ? press.pressTitle : press.url}</a>
          </div>
        ))}
      </div>
      <div className='program-press'>
        { related.length > 0 && <h4>Related Programs</h4> }    
        {related?.map((link, index) => (
          <div className='row' key={index}>
            <a href={`/programs/${link.id}/${link.label.replace(/\s+/g, '-')}`} target='_blank' rel='noreferrer'>{link.label}</a>
          </div>
        ))}
      </div>
    </div>
  )
}
export default ProgramDescription
