import { useEffect, useState } from 'react'
import MySpinner from '../utilities/MySpinner'
import { Link } from 'react-router-dom'
import { getGenreUrl } from '../utilities/helpers'

const ProgramTombstone = ({ program }) => {
  const [ urlSlug, setUrlSlug ] = useState([])

  useEffect(() => {
    if (program && program.genre && program.genre.length > 0) {
      // Map each genre to its URL slug
      const slugs = program.genre.map(genre => getGenreUrl(genre));
      setUrlSlug(slugs);
    } else {
      // Reset or clear slugs if no genres are present
      setUrlSlug([]);
    }
  }, [program]);

  if (!program || !(urlSlug.length > 0)) return <MySpinner />;

  return (
    <div className='program-details' itemscope itemtype='https://schema.org/TVSeries' itemid={`#${program.idNumber}`}>
      <ul className='program-tombstone-metadata' >
        <li className='metadata-key' itemprop='numberOfEpisodes'>Episodes & Length:</li>
        <li className='metadata-field'>{program.epCount.replace(/\//g, 'x')}</li>
        <li className='metadata-key'>Genre:</li>
        <li className='metadata-field'>
        {program.genre.map((genre, index, array) => {
          return (
            <Link to={`/genres/${urlSlug[index]}`} itemprop='genre'>
              {genre}{index < array.length - 1 ? ', ' : ''}
            </Link>
          );
        })}
        </li>  
        <li className='metadata-key' itemprop='datePublished'>Production Year:</li> 
        <li className='metadata-field'>{program.prodYears}</li>
        <li className='metadata-key'>Format:</li>
        <li className='metadata-field'>{program.vQ[0]}</li>
      </ul>
    </div>
  )
}
export default ProgramTombstone
