import { useContext, useState, useEffect } from 'react'
import ProgramContext from '../../context/ProgramContext'
import ProgramCard from '../program/ProgramCard'
import MySpinner from '../utilities/MySpinner'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { reconstructTitle } from '../utilities/helpers'

const GenreBrowse = () => {
  const params = useParams()
  const genreId = parseInt(params.id)
  const [ filteredPrograms, setFilteredPrograms ] = useState([])
  const [ genre, setGenre ] = useState(null)
  const { programs, genres } = useContext(ProgramContext)  
  const genreName = params.genre === 'ife' ? 'IFE' : reconstructTitle(params.genre)

  useEffect(() => {
    setGenre(genres.find(genre => genre.id === String(genreId)))
  }, [genres, genreId])
  
  useEffect(() => {
    if(genre && programs){
      const filtered = programs.filter(program => program.genre.includes(genre.name.replace(',', '')));
      filtered.sort((a, b) => a.title.localeCompare(b.title));
      setFilteredPrograms(filtered);
    }
  }, [genre, programs])
  
  if(!genre) {
    return <MySpinner />
  }

  return (
    <>
      <div className='pt-3' style={{textAlign: 'center'}}>
        <h1 className='genre-name fw-bold'>{genreName}</h1>
      </div>      
      <Container className='search-container pt-3'>
        { filteredPrograms.map(program => {
          return (
            <Link to={`/programs/${program.idNumber}/${program.title}`} key={program.idNumber} state={{ program: {...program}}} onClick={()=> window.scroll(0, 0)}>
              <ProgramCard program={program} />
            </Link>
          )
        })}
      </Container>
    </>  
  )
}
export default GenreBrowse