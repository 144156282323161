import { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import MySpinner from '../utilities/MySpinner'
import ProgramHeroCarousel from './ProgramHeroCarousel'
import MobileTombstone from './MobileTombstone'
import ProgramMetadata from './ProgramMetadata'
import ProgramContext from '../../context/ProgramContext'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import ProgramVideo from './ProgramVideo'
import Container from 'react-bootstrap/Container'
import { reconstructTitle } from '../utilities/helpers'
import { Helmet } from 'react-helmet'

const ProgramPage = () => {
  const { programs, loadingPrograms } = useContext(ProgramContext)
  const params = useParams()
  const navigate = useNavigate()
  // Initialize program with null
  const [program, setProgram] = useState()
  const id = params.id
  useDocumentTitle(`${reconstructTitle(params.title)} - APT Worldwide`)

  useEffect(() => {
    let timeout
    if(!loadingPrograms) {
      const theProgram = programs.find((program) => program.idNumber === id)
      setProgram(theProgram)
      if(!theProgram) {
        timeout = setTimeout(() => {
          navigate('/no-longer-offered')
        }, 15000)
      }
    }
    return () => clearTimeout(timeout)
  }, [id, programs, navigate, loadingPrograms])

  if(!program || loadingPrograms) return <MySpinner />

  return (
    <>
      <Helmet>
        <title>{program.featuredTitle} - APT Worldwide</title>
        <meta name='description' content={program.shortDesc} />
        <meta name='keywords' content={program.mtKeys.join(', ')} />
      </Helmet>
      <div className='program-container' itemscope itemtype='https://schema.org/TVSeries' itemid={`#${program.idNumber}`}>
        <ProgramHeroCarousel program={program} />
        <div className={ program.featuredTitle.length > 40 ? 'long-title mt-4' : 'program-title mt-4'} itemprop='name'>
          {program.featuredTitle}
        </div>
        <div className='mobile-title'>
          <h1 className='text-center' itemprop='name'>{program.featuredTitle}</h1>
        </div>
        <ProgramVideo program={program} />
        <Container>
          <MobileTombstone program={program} />
          <ProgramMetadata program={program} /> 
        </Container>
      </div>
    </>
  )
}
export default ProgramPage
