import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'

const GenreDropdownItem = ({genre, hideTooltip}) => {
  const [ urlSlug, setUrlSlug ] = useState()

  useEffect(() => {
    const rawTitle = genre.name
    const newTitle = rawTitle.replace(/\s+/g, '-').toLowerCase()
    setUrlSlug(newTitle)
  }, [genre])

  return (
    <Nav.Item 
      role='menuitem' 
      key={genre.id}
      onClick={hideTooltip}
      >
      <Link to={`/genres/${genre.id}/${urlSlug}`} state={{genre}} className='text-nowrap' onClick={hideTooltip}>{genre.name}</Link>
    </Nav.Item>
  )
}
export default GenreDropdownItem