import React, { useState, useEffect, useContext, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup' 
import { Search } from 'react-bootstrap-icons'
import ProgramContext from '../../context/ProgramContext'

const SearchInput = ({ onSearchSubmit }) => {
  // State to hold the search input value
  const [searchTerm, setSearchTerm] = useState('')
  // State to control the visibility of the dropdown
  const [showDropdown, setShowDropdown] = useState(false)
  const { mtKeys } = useContext(ProgramContext)
  const searchTermArr = Object.values(mtKeys).map(key => key.name)

  const keyedSearches = {
    'world war 1': 'world war i',
    'first world war': 'world war i',
    'world war one': 'world war i',
    'world war 2': 'world war ii',
    'second world war': 'world war ii',
    'world war two': 'world war ii'
  }
  // ALLOWS THE SEARCH DROPDOWN TO CLOSE WHEN THE SCREEN IS CLICKED ON
  const dropdownRef = useRef(null)
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const capitalize = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase())
  }

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault() // Prevent the default form submit action
    const normalizedSearchTerm = searchTerm.toLowerCase();
    let searchValue = normalizedSearchTerm; // Default to the normalized searchTerm

    // Create a temporary object with lowercased keys for case insensitive key checking
    const normalizedKeyedSearches = Object.keys(keyedSearches).reduce((acc, key) => {
        acc[key.toLowerCase()] = keyedSearches[key]; // Preserve the original case for values
        return acc;
    }, {});

    // Check if normalizedSearchTerm is a key in the normalizedKeyedSearches object
    if (normalizedSearchTerm in normalizedKeyedSearches) {
        searchValue = normalizedKeyedSearches[normalizedSearchTerm]; // Use the corresponding mapped value
    }

    onSearchSubmit(searchValue); 
    setSearchTerm('')
    setShowDropdown(false) // Hide the dropdown after search
  }

  // Function to handle search input changes
  const handleInputChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)
    setShowDropdown(value !== '') // Show dropdown only if searchTerm is not empty
  }

  // Filter mtKeys based on the searchTerm
  const filteredTerms = searchTerm
    ? searchTermArr.filter((key) =>
        key.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : []

  return (
    <div style={{ position: 'relative'}} ref={dropdownRef}>
      <Form className='nav-search-form desktop-search' onSubmit={handleSubmit}>
        <InputGroup className='mb-3 nav-search-form'>
          <Form.Control
            type='text'
            className='form-control-sm'
            value={searchTerm}
            onChange={handleInputChange} // Set the onChange handler
            aria-label='Search'
          />
          <InputGroup.Text onClick={handleSubmit}>
            <Search className='nav-search' aria-label='Submit search' />
          </InputGroup.Text>
        </InputGroup>
        {showDropdown && filteredTerms.length > 0 && (
          <ListGroup className='search-dropdown'>
            {filteredTerms.map((term, index) => (
              <ListGroup.Item key={index} onClick={() => {
                setSearchTerm(term)
                setShowDropdown(false)
                onSearchSubmit(term) // Optionally submit the search on term click
                setSearchTerm('')
              }}>
                {capitalize(term)}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Form>
    </div>
  )
}

export default SearchInput
